/// <reference path='interfaces/IAWSignatureDocument.d.ts'/>
/// <reference path='interfaces/ISignatureDocumentData.d.ts'/>

import { aw_api, AxiosResponse } from '@/aw_api';
import SignatureDocument from './models/GBS.AW.SCS/Items/signatureDocumentModel';
import CloudDocument from './models/GBS.AW.SCS/cloudDocumentModel';
import { environment } from '@/environment';
import { EventBus } from '@/event-bus';
import differenceBy from 'lodash-es/differenceBy';
import intersectionBy from 'lodash-es/intersectionBy';
import unionBy from 'lodash-es/unionBy';
import merge from 'lodash-es/merge';
import sortBy from 'lodash-es/sortBy';
import { auth } from '@/auth';

// document data and actions
// should be able to reconstruct a clouddocument signature documents item
// should be able to merge and store SCS data and API data to build a true document

class DocumentHandler {
	private _signatureMetaData: IDocumentMetaData[] = [];
	private _signatureUserData: IDocumentUserData[] = [];

	get SignatureDocuments(): IAWSignatureDocument[] {
		// debugger;
		this._signatureMetaData = this._signatureMetaData.map(i => {
			const thisMetaData = i;
			thisMetaData.ShapeModel = thisMetaData.ShapeModel.map(j => {
				const thisShape = j;
				thisShape.zone = parseInt(
					(thisShape.zone + '').replace(/\D/g, '')
				);
				return thisShape;
			});
			return thisMetaData;
		});
		return merge(
			[],
			sortBy(
				intersectionBy(
					this._signatureMetaData,
					this._signatureUserData,
					'DocumentId'
				),
				'DocumentId'
			),
			sortBy(this._signatureUserData, 'DocumentId')
		);
	}

	public ModifyDocument(
		updatedDocument: IAWSignatureDocument,
		shouldFinalize?: boolean
	): void {
		const currentDocumentUserData = this._signatureUserData.find(
			i => i.DocumentId === updatedDocument.DocumentId
		);

		if (currentDocumentUserData) {
			currentDocumentUserData.Data = unionBy(
				updatedDocument.Data,
				currentDocumentUserData.Data,
				'Id'
			).filter(i => i.Value !== null && i.Value !== undefined);
			currentDocumentUserData.Status = shouldFinalize
				? 'pending'
				: 'edited';
		}

		EventBus.$emit('forms');
	}

	public async ModifyDocumentAsync(
		updatedDocument: IAWSignatureDocument,
		shouldFinalize?: boolean
	): Promise<void> {
		return new Promise(resolve => {
			const currentDocumentUserData = this._signatureUserData.find(
				i => i.DocumentId === updatedDocument.DocumentId
			);

			if (currentDocumentUserData) {
				currentDocumentUserData.Data = unionBy(
					updatedDocument.Data,
					currentDocumentUserData.Data,
					'Id'
				).filter(i => i.Value !== null && i.Value !== undefined);
				currentDocumentUserData.Status = shouldFinalize
					? 'pending'
					: 'edited';
			}

			EventBus.$emit('forms');
			resolve(); // Resolves the promise once document modification is done
		});
	}

	public UpdateDocuments(cloudDocument: CloudDocument): void {
		if (
			!cloudDocument ||
			!cloudDocument.Items ||
			!cloudDocument.Items.SignatureDocuments
		)
			return;
		this._updateSignatureDocuments(cloudDocument.Items.SignatureDocuments);
	}
	public async UpdateDocumentsAsync(
		cloudDocument: CloudDocument
	): Promise<void> {
		if (
			!cloudDocument ||
			!cloudDocument.Items ||
			!cloudDocument.Items.SignatureDocuments
		) {
			return;
		}
		await this._updateSignatureDocumentsAsync(
			cloudDocument.Items.SignatureDocuments
		);
	}

	public GetDocumentPageImages(
		documentId: string,
		onSuccess: Function,
		onComplete: Function
	): void {
		const thisDocument = this._signatureMetaData.find(
			i => i.DocumentId === documentId
		);
		if (!thisDocument) {
			onComplete();
			return;
		}
		if (
			thisDocument.PageImages.length >= this._getPageCount(thisDocument)
		) {
			thisDocument.PageImages = sortBy(thisDocument.PageImages, 'page');
			onSuccess(thisDocument);
			onComplete();
			return;
		}

		aw_api.axios
			.get(`v1/SCS/SignatureDocument/Images?id=${documentId}`)
			.then((response: AxiosResponse) => {
				if (response.status === 200) {
					thisDocument.PageImages = sortBy(response.data, 'page');
					EventBus.$emit('forms');
					onSuccess(thisDocument);
					onComplete();
				}
			})
			.catch(_ => {
				// eslint-disable-next-line no-console
				// console.error('An error occurred getting document page images')
			});
	}

	public GetFormSourceUrl(documentId: string) {
		const baseUrl = `${environment.variable.API_ROUTE}`.endsWith('/')
			? environment.variable.API_ROUTE
			: `${environment.variable.API_ROUTE}/`;
		return `${baseUrl}${this.GetFormSourceApiUrl(documentId)}`;
	}

	private GetFormSourceApiUrl(documentId: string) {
		return `v1/documentSource/${documentId}`;
	}

	public GetFormSourceFile(documentId: string, documentName: string) {
		//Download Source File
		aw_api.axios
			.get(this.GetFormSourceApiUrl(documentId), {
				responseType: 'arraybuffer'
			})
			.then((response: AxiosResponse) => {
				const sourceFile = URL.createObjectURL(
					new Blob([response.data])
				);
				const sourceLink = document.createElement('a');
				sourceLink.href = sourceFile;
				sourceLink.setAttribute('download', documentName);
				document.body.appendChild(sourceLink);
				sourceLink.click();
				document.body.removeChild(sourceLink);
			});
	}

	public ValidateField(field: ISignatureDocumentField): boolean {
		return this.ValidateFieldValue(
			field.isRequired,
			field.type,
			field.value
		);
	}
	public ValidateFieldValue(
		isRequired: boolean,
		type: string,
		value: any
	): boolean {
		if (
			!isRequired ||
			(type === 'checkbox' && !Array.isArray(value) && value)
		)
			return true; // checkbox always has an answer
		if (
			type === 'signature' &&
			value &&
			(value + '').trim().toLowerCase() === 'r'
		)
			return false;
		if (Array.isArray(value)) {
			if (value.length > 0) {
				return true;
			} else {
				return false;
			}
		} else {
			return value && (value + '').trim().length ? true : false;
		}
	}

	public GetFullFieldIdentifier(field: ISignatureDocumentField): string {
		let result: string = '';
		let prefix: string = '';
		let suffix: string = '';
		switch (field.type.toLowerCase()) {
			case 'textbox':
				prefix = 'GBS_SIG__TEXTBOX_';
				if (field.isDropdown || false) {
					prefix += 'options-' + field.custom + '_';
				}
				break;
			case 'noneditabletext':
				prefix = 'GBS_SIG_NONEDITABLETEXT__';
				break;
			case 'checkbox':
				prefix = 'GBS_SIG__';
				break;
			case 'radio':
				prefix = `GBS_SIG__rdoGrp-${field.rdoGroup}_`;
				suffix = `~${field.controlValue}`;
				break;
			case 'merge':
				prefix = 'GBS_MERGE__';
				if (field.isEditable || field.isViewableInMobile || false) {
					prefix += 'isEditable_';
				}
				break;
			case 'facilitydetails':
				prefix = 'GBS_FACILITYDETAILS__';
				break;
			case 'auto':
				prefix = 'GBS_SIG__namedate_';
				break;
			case 'barcode':
				prefix = 'GBS_MERGE__BARCODE_';
				break;
			case 'logo':
				prefix = 'GBS_LOGO__';
				break;
			case 'witness':
				prefix = 'GBS_WITNESS__';
				break;
			case 'signature':
				prefix = 'GBS_SIG__';
				break;
		}
		result = prefix + field.name + suffix;

		return result;
	}

	public async SaveDocumentsToSCS(
		shouldNotSubmitEveryDocument?: boolean,
		submitAllDocuments?: boolean,
		callback?: any
	): Promise<void> {
		return new Promise((resolve, reject) => {
			const _this = this;
			const updatedDocuments: SignatureDocument[] = _this._signatureUserData
				.filter(i => {
					// If submitAllDocuments is true, include all documents, otherwise filter by status
					if (submitAllDocuments) {
						return true; // Include all documents
					} else {
						return ['pending', 'edited', 'pendingreview'].includes(
							i.Status.toLowerCase()
						);
					}
				})
				.map(
					i =>
						<SignatureDocument>{
							DocId: i.DocumentId,
							Status:
								((i.Status.toLowerCase() === 'pending' ||
									i.Status.toLowerCase() ===
										'pendingreview') &&
									!shouldNotSubmitEveryDocument) ||
								submitAllDocuments
									? submitAllDocuments
										? 'submitted' // If submitting all documents, set status to 'submitted'
										: 'pendingreview'
									: i.Status,
							Updated: _this._getFormattedDateString(new Date()),
							B64Data: _this._getb64EncodeUnicode(
								JSON.stringify(i.Data)
							),
							ActiveDocId: i.DocumentId
						}
				);

			// If there are updated documents, send the request
			if (updatedDocuments.length > 0) {
				aw_api.axios
					.post(
						`v1/SCS/SignatureDocument/${auth.scsDocumentId}`,
						updatedDocuments
					)
					.then((response: AxiosResponse) => {
						if (response.status === 202) {
							_this._signatureUserData = _this._signatureUserData.map(
								i => {
									let current = updatedDocuments.find(
										j => j.DocId === i.DocumentId
									);
									if (!current) return i;

									i.Status = current.Status;
									return i;
								}
							);

							// If there is a callback, invoke it
							if (callback && typeof callback === 'function') {
								callback();
							}
							resolve(); // Resolve the promise if the request is successful
						} else {
							reject(
								new Error(
									'Failed to upload signature documents.'
								)
							);
						}
					})
					.catch(error => {
						reject(error); // Reject the promise in case of an error
					});
			} else {
				resolve(); // Resolve the promise if there are no documents to process
			}
		});
	}

	public SaveCompletedProcessHash(userHash: string) {
		if (
			this._signatureUserData.filter(i =>
				['new', 'edited', 'pending'].includes(i.Status.toLowerCase())
			).length == 0
		) {
			aw_api.axios.post(
				`v1/CompletedProcess/${auth.vaultId}/${userHash}`
			);
		}
	}
	public async SaveCompletedProcessHashAsync(
		userHash: string
	): Promise<void> {
		// debugger;
		// console.log(
		// 	this._signatureUserData.filter(i =>
		// 		['new', 'edited'].includes(i.Status.toLowerCase())
		// 	).length == 0
		// );
		if (
			this._signatureUserData.filter(i =>
				['new', 'edited', 'pending'].includes(i.Status.toLowerCase())
			).length == 0
		) {
			await aw_api.axios.post(
				`v1/CompletedProcess/${auth.vaultId}/${userHash}`
			);
		}
	}

	public GetCompletedProcessHash(): boolean {
		return this._signatureUserData.every(
			i => !['new', 'edited', 'pending'].includes(i.Status.toLowerCase())
		);
	}

	public SetDocumentsToEdited(): void {
		this._signatureUserData.forEach(i => {
			i.Status = 'edited';
		});
		EventBus.$emit('forms');
	}

	private _getPageCount(dmd: IDocumentMetaData): number {
		if (!dmd) return 0;
		return dmd.ShapeModel.map(i => i.page).reduce(
			(i, j) => Math.max(i, j),
			1
		);
	}

	private _updateSignatureDocuments(
		currentSignatureDocuments: SignatureDocument[]
	) {
		const _this = this;
		let tmpUserData: IDocumentUserData[] = [];
		let tmpMetaData: IDocumentMetaData[] = [];

		currentSignatureDocuments.forEach(thisDoc => {
			const thisDocId = thisDoc.ActiveDocId || thisDoc.DocId;
			if (tmpUserData.find(i => i.DocumentId === thisDocId)) return; // Don't process duplicates

			let existingUserData = <IDocumentUserData>(
				this._signatureUserData.find(i => i.DocumentId === thisDocId)
			);
			if (existingUserData && existingUserData.Status !== 'new') {
				tmpUserData.push(existingUserData); // Keep existing data, if applicable
				return;
			}

			let thisUserData = JSON.parse(atob(thisDoc.B64Data || '') || '{}');
			if (thisUserData && !thisUserData.Fields) {
				thisUserData = { Fields: thisUserData };
			}
			tmpUserData.push({
				DocumentId: thisDocId,
				Status: thisDoc.Status,
				Data: (<ISignatureDocumentData>thisUserData || { Fields: [] })
					.Fields
			});
		});

		if (
			!this._activeMetaRequest &&
			tmpUserData &&
			tmpUserData.length > 0 &&
			differenceBy(tmpUserData, tmpMetaData, 'DocumentId').length > 0
		) {
			_this._activeMetaRequest = true;
			setTimeout(() => (_this._activeMetaRequest = false), 5000);

			aw_api.axios
				.get(
					`v1/SCS/SignatureDocument/MetaData?id=${tmpUserData
						.map(i => i.DocumentId)
						.join('&id=')}`
				)
				.then((response: AxiosResponse) => {
					if (response.status === 200) {
						response.data
							.filter(
								i =>
									i.Document.DocumentId &&
									i.Document.DocumentName &&
									i.Document.ShapeModel &&
									tmpUserData.find(
										j =>
											j.DocumentId ===
											i.Document.DocumentId
									)
							)
							.forEach(i =>
								tmpMetaData.push({
									//This one.
									DocLangGroupId: i.Document.DocLangGroupId,
									DocumentId: i.Document.DocumentId,
									DocumentName: i.Document.DocumentName,
									Language: i.Document.Language,
									Description:
										i.Document.Description ||
										i.Document.DocumentName,
									PageImages: i.Document.PageImages || [],
									ShapeModel: JSON.parse(
										i.Document.ShapeModel
									),
									Rank: i.Document.Rank,
									HasSource: i.HasSource
								})
							);
						tmpUserData = intersectionBy(
							tmpUserData,
							tmpMetaData,
							'DocumentId'
						);
						_this._signatureUserData = tmpUserData;
						_this._signatureMetaData = unionBy(
							tmpMetaData,
							_this._signatureMetaData,
							'DocumentId'
						);
						EventBus.$emit('forms');
					}
				})
				.catch(x => {
					// eslint-disable-next-line no-console
					// console.error(
					// 	x,
					// 	'An error occurred retrieving document metadata.'
					// )
				});
		}
	}
	private async _updateSignatureDocumentsAsync(
		currentSignatureDocuments: SignatureDocument[]
	) {
		const _this = this;
		let tmpUserData: IDocumentUserData[] = [];
		let tmpMetaData: IDocumentMetaData[] = [];

		for (const thisDoc of currentSignatureDocuments) {
			const thisDocId = thisDoc.ActiveDocId || thisDoc.DocId;
			if (tmpUserData.find(i => i.DocumentId === thisDocId)) return; // Don't process duplicates

			let existingUserData = <IDocumentUserData>(
				this._signatureUserData.find(i => i.DocumentId === thisDocId)
			);
			if (existingUserData && existingUserData.Status !== 'new') {
				tmpUserData.push(existingUserData); // Keep existing data, if applicable
				return;
			}

			let thisUserData = JSON.parse(atob(thisDoc.B64Data || '') || '{}');
			if (thisUserData && !thisUserData.Fields) {
				thisUserData = { Fields: thisUserData };
			}
			tmpUserData.push({
				DocumentId: thisDocId,
				Status: thisDoc.Status,
				Data: (<ISignatureDocumentData>thisUserData || { Fields: [] })
					.Fields
			});
		}

		if (
			!_this._activeMetaRequest &&
			tmpUserData &&
			tmpUserData.length > 0 &&
			differenceBy(tmpUserData, tmpMetaData, 'DocumentId').length > 0
		) {
			_this._activeMetaRequest = true;
			setTimeout(() => (_this._activeMetaRequest = false), 5000);

			try {
				const response: AxiosResponse = await aw_api.axios.get(
					`v1/SCS/SignatureDocument/MetaData?id=${tmpUserData
						.map(i => i.DocumentId)
						.join('&id=')}`
				);
				if (response.status === 200) {
					response.data
						.filter(
							i =>
								i.Document.DocumentId &&
								i.Document.DocumentName &&
								i.Document.ShapeModel &&
								tmpUserData.find(
									j => j.DocumentId === i.Document.DocumentId
								)
						)
						.forEach(i =>
							tmpMetaData.push({
								//This one.
								DocLangGroupId: i.Document.DocLangGroupId,
								DocumentId: i.Document.DocumentId,
								DocumentName: i.Document.DocumentName,
								Language: i.Document.Language,
								Description:
									i.Document.Description ||
									i.Document.DocumentName,
								PageImages: i.Document.PageImages || [],
								ShapeModel: JSON.parse(i.Document.ShapeModel),
								Rank: i.Document.Rank,
								HasSource: i.HasSource
							})
						);
					tmpUserData = intersectionBy(
						tmpUserData,
						tmpMetaData,
						'DocumentId'
					);
					_this._signatureUserData = tmpUserData;
					_this._signatureMetaData = unionBy(
						tmpMetaData,
						_this._signatureMetaData,
						'DocumentId'
					);
					EventBus.$emit('forms');
				}
			} catch (error) {
				// console.error(
				// 	error,
				// 	'An error occurred retrieving document metadata.'
				// );
			}
		}
	}

	private _activeMetaRequest = false;

	private _getFormattedDateString(date: Date): string {
		const pad = (i: string | number) => ('0' + i).slice(-2);
		return `${
			pad(date.getUTCMonth() + 1) // Zero-based
		}-${pad(date.getUTCDate())}-${date.getUTCFullYear()} ${pad(
			date.getUTCHours()
		)}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())} Z`;
	}

	private _getb64EncodeUnicode(str: string): string {
		return btoa(
			encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(
				match,
				p1
			) {
				return String.fromCharCode(parseInt(p1, 16));
			})
		);
	}
}

export const documentHandler = new DocumentHandler();
